import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCIX-BOxVrO0ljgZM7c4h65PbSeK82SX24",
  authDomain: "cashstash-d45ec.firebaseapp.com",
  projectId: "cashstash-d45ec",
  storageBucket: "cashstash-d45ec.firebasestorage.app",
  messagingSenderId: "1064702196543",
  appId: "1:1064702196543:web:19d9199c23f0325f39b18a",
  measurementId: "G-6G1MD9595F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider(); 