import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Stack, TextField, Switch, FormControlLabel } from '@mui/material';
import { MapContainer, TileLayer, Circle, Marker, useMap, useMapEvents } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAdmin } from '../hooks/useAdmin';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

interface GameData {
  stashLocation: [number, number];
  circle: {
    center: [number, number];
    radius: number;
  };
  active: boolean;
  winnerUid?: string;
  winTime?: Date;
}

// Component to check if stash is within circle
const CircleCheck: React.FC<{
  stashLocation: [number, number];
  circleCenter: [number, number];
  radius: number;
  onValidityChange: (isValid: boolean) => void;
}> = ({ stashLocation, circleCenter, radius, onValidityChange }) => {
  const map = useMap();
  
  useEffect(() => {
    const circle = L.circle(circleCenter, { radius });
    const isWithinCircle = map.distance(stashLocation, circleCenter) <= radius;
    onValidityChange(isWithinCircle);
  }, [stashLocation, circleCenter, radius, map, onValidityChange]);

  return null;
};

// Component to handle map clicks and show proposed circle
const MapClickHandler: React.FC<{
  onCircleProposed: (center: [number, number]) => void;
  proposedCenter: [number, number] | null;
  proposedRadius: number;
}> = ({ onCircleProposed, proposedCenter, proposedRadius }) => {
  useMapEvents({
    click: (e) => {
      onCircleProposed([e.latlng.lat, e.latlng.lng]);
    },
  });

  return proposedCenter ? (
    <Circle
      center={proposedCenter}
      radius={proposedRadius}
      pathOptions={{ color: 'orange', fillOpacity: 0.1 }}
    />
  ) : null;
};

const Admin: React.FC = () => {
  const { isAdmin, loading } = useAdmin();
  const navigate = useNavigate();
  const [gameData, setGameData] = useState<GameData>({
    stashLocation: [25.55, 92.99],
    circle: {
      center: [25.55, 92.99],
      radius: 1000,
    },
    active: false,
  });
  const [proposedCenter, setProposedCenter] = useState<[number, number] | null>(null);
  const [proposedRadius, setProposedRadius] = useState<number>(1000);
  const [isValid, setIsValid] = useState(false);
  const [winnerInfo, setWinnerInfo] = useState<{
    displayName?: string;
    email?: string;
  } | null>(null);

  useEffect(() => {
    const loadGameData = async () => {
      try {
        const gameDoc = await getDoc(doc(db, 'game', 'current'));
        
        if (gameDoc.exists()) {
          const data = gameDoc.data();
          setGameData({
            stashLocation: data.stashLocation,
            circle: {
              center: data.circle.center,
              radius: data.circle.radius,
            },
            active: data.active,
            winnerUid: data.winnerUid,
            winTime: data.winTime?.toDate(),
          });
          setProposedRadius(data.circle.radius);

          // If there's a winner, fetch their info
          if (data.winnerUid) {
            const winnerDoc = await getDoc(doc(db, 'users', data.winnerUid));
            if (winnerDoc.exists()) {
              setWinnerInfo(winnerDoc.data());
            }
          }
        }
      } catch (error) {
        console.error('Error loading game data:', error);
      }
    };

    loadGameData();
  }, []);

  const handleSave = async () => {
    if (!isValid) {
      alert('Stash location must be within the circle!');
      return;
    }

    try {
      const currentGameData: GameData = {
        stashLocation: gameData.stashLocation,
        circle: {
          center: proposedCenter || gameData.circle.center,
          radius: proposedRadius,
        },
        active: gameData.active,
        ...(gameData.winnerUid && {
          winnerUid: gameData.winnerUid,
          winTime: gameData.winTime,
        }),
      };

      // Save game data
      await setDoc(doc(db, 'game', 'current'), currentGameData);

      // Update local state
      setGameData(prev => ({
        ...prev,
        circle: {
          center: proposedCenter || prev.circle.center,
          radius: proposedRadius,
        }
      }));
      setProposedCenter(null);

      // Create a backup with timestamp
      const timestamp = new Date().toISOString();
      await setDoc(doc(db, 'game_backups', timestamp), {
        ...currentGameData,
        backupTime: new Date(),
      });

      alert('Game data saved and backed up successfully!');
    } catch (error) {
      console.error('Error saving game data:', error);
      alert('Error saving game data');
    }
  };

  const handleReset = async () => {
    if (!window.confirm('Are you sure you want to reset the game? This will clear the winner.')) {
      return;
    }

    try {
      await setDoc(doc(db, 'game', 'current'), {
        stashLocation: gameData.stashLocation,
        circle: {
          center: gameData.circle.center,
          radius: gameData.circle.radius,
        },
        active: gameData.active,
        winnerUid: null,
        winTime: null,
      });

      setGameData({
        ...gameData,
        winnerUid: undefined,
        winTime: undefined,
      });
      setWinnerInfo(null);

      alert('Game reset successfully!');
    } catch (error) {
      console.error('Error resetting game:', error);
      alert('Error resetting game');
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (!isAdmin) {
    navigate('/');
    return null;
  }

  const mapSize = 'min(calc(100vh - 400px), 100vw)';

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Typography variant="h5" component="h1" gutterBottom>
            Game Management
          </Typography>

          {gameData.winnerUid && winnerInfo && (
            <Paper 
              elevation={2} 
              sx={{ 
                p: 2, 
                bgcolor: 'success.dark',
                border: '1px solid #00ff00',
              }}
            >
              <Typography variant="h6" gutterBottom>
                STASH FOUND!
              </Typography>
              <Typography>
                Winner: {gameData.winnerUid}
              </Typography>
              <Typography>
                Time: {gameData.winTime?.toLocaleString()}
              </Typography>
              <Button
                variant="outlined"
                color="error"
                onClick={handleReset}
                sx={{ mt: 1 }}
              >
                Reset Game
              </Button>
            </Paper>
          )}

          <Stack direction="row" spacing={2}>
            <TextField
              label="Stash Latitude"
              type="number"
              value={gameData.stashLocation[0]}
              onChange={(e) => setGameData({
                ...gameData,
                stashLocation: [parseFloat(e.target.value), gameData.stashLocation[1]]
              })}
            />
            <TextField
              label="Stash Longitude"
              type="number"
              value={gameData.stashLocation[1]}
              onChange={(e) => setGameData({
                ...gameData,
                stashLocation: [gameData.stashLocation[0], parseFloat(e.target.value)]
              })}
            />
          </Stack>

          <TextField
            label="Circle Radius (meters)"
            type="number"
            value={proposedRadius}
            onChange={(e) => setProposedRadius(parseFloat(e.target.value))}
          />

          <FormControlLabel
            control={
              <Switch
                checked={gameData.active}
                onChange={(e) => setGameData({
                  ...gameData,
                  active: e.target.checked
                })}
              />
            }
            label="Game Active"
          />

          <Typography variant="body2" color="text.secondary">
            Click on the map to set the circle center. The orange circle shows the proposed position and radius.
          </Typography>

          <Box 
            sx={{ 
              width: mapSize,
              height: mapSize,
              border: '2px solid #1976d2',
              borderRadius: 2,
              overflow: 'hidden',
              alignSelf: 'center'
            }}
          >
            <MapContainer
              center={gameData.circle.center}
              zoom={13}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                attribution=""
              />
              <Circle
                center={gameData.circle.center}
                radius={gameData.circle.radius}
                pathOptions={{ color: 'red' }}
              />
              <MapClickHandler
                onCircleProposed={setProposedCenter}
                proposedCenter={proposedCenter}
                proposedRadius={proposedRadius}
              />
              <Marker position={gameData.stashLocation} />
              <CircleCheck
                stashLocation={gameData.stashLocation}
                circleCenter={proposedCenter || gameData.circle.center}
                radius={proposedRadius}
                onValidityChange={setIsValid}
              />
            </MapContainer>
          </Box>

          <Typography color={isValid ? 'success.main' : 'error.main'}>
            {isValid ? 'Stash location is within circle' : 'Warning: Stash location is outside circle!'}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!isValid}
          >
            Save Game Data
          </Button>
          <Typography variant="caption" color="text.secondary" align="center">
            Note: Game data is automatically backed up when saved
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};

export default Admin; 