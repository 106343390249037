import React, { useState } from 'react';
import { Box, Container, Typography, Dialog, DialogTitle, DialogContent, IconButton, Button, Paper } from '@mui/material';
import { Help as HelpIcon, Security as SecurityIcon } from '@mui/icons-material';
import Map from './Map';

const Home: React.FC = () => {
  const [helpOpen, setHelpOpen] = useState(false);

  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <Dialog 
        open={helpOpen} 
        onClose={() => setHelpOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            background: 'linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%)',
            border: '1px solid #00ff00',
            boxShadow: '0 0 20px rgba(0, 255, 0, 0.2)',
          }
        }}
      >
        <DialogTitle sx={{ 
          fontFamily: "'Share Tech Mono', monospace",
          color: '#00ff00',
          borderBottom: '1px solid #00ff00'
        }}>
          MISSION_BRIEFING
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph sx={{ color: '#fff', mt: 2 }}>
            CLASSIFIED INTEL: High-value asset detected in Northeast India sector. Your mission:
          </Typography>
          
          <Box component="ul" sx={{ pl: 2, color: '#00ff00' }}>
            <Typography component="li" variant="body1" paragraph sx={{ color: '#fff' }}>
              Target zone marked by red perimeter - asset confirmed within boundary
            </Typography>
            <Typography component="li" variant="body1" paragraph sx={{ color: '#fff' }}>
              Surveillance zone will narrow over time for precision targeting
            </Typography>
            <Typography component="li" variant="body1" paragraph sx={{ color: '#fff' }}>
              Deploy satellite scan to analyze terrain for asset signature
            </Typography>
            <Typography component="li" variant="body1" paragraph sx={{ color: '#fff' }}>
              First agent to pinpoint exact coordinates achieves mission success
            </Typography>
          </Box>
          
          <Button 
            variant="outlined"
            fullWidth 
            onClick={() => setHelpOpen(false)}
            sx={{ 
              mt: 2,
              color: '#00ff00',
              borderColor: '#00ff00',
              '&:hover': {
                borderColor: '#00ff00',
                backgroundColor: 'rgba(0, 255, 0, 0.1)',
              }
            }}
          >
            ACKNOWLEDGE
          </Button>
        </DialogContent>
      </Dialog>

      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'flex-start',
        mb: 2,
        p: 2,
        background: 'linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%)',
        borderRadius: 1,
        border: '1px solid #00ff00',
        boxShadow: '0 0 10px rgba(0, 255, 0, 0.1)',
      }}>
        <IconButton 
          onClick={() => setHelpOpen(true)}
          sx={{ 
            color: '#00ff00',
            '&:hover': {
              backgroundColor: 'rgba(0, 255, 0, 0.1)',
            }
          }}
        >
          <SecurityIcon />
          <Typography 
            variant="h6" 
            sx={{ 
              ml: 1,
              fontFamily: "'Share Tech Mono', monospace",
              letterSpacing: '0.1em',
              color: '#00ff00',
            }}
          >
            MISSION_DETAILS
          </Typography>
        </IconButton>
      </Box>

      <Box sx={{ my: 2 }}>
        <Map />
      </Box>
    </Container>
  );
};

export default Home; 